import React, { useState, useEffect } from 'react';
import { TextField, Button, Checkbox, FormControlLabel, Grid, Divider, Alert, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import CloseIcon from '@mui/icons-material/Close';
import EditReviewPageMessages from './edit-messages';
import EditLinks from './edit-links';
import EditUserLogo from './edit-logo';
import api from '../../../services/admin-api';
import EditSubscribers from './edit-subscribers';
import { useContext } from "react";
import { AuthContext } from "../../../auth-context";

const StyledTextField = styled(TextField)({
    marginBottom: '15px',
    '& .MuiInputLabel-root': {
        marginBottom: '10px'
    },
    '& .MuiInputBase-input': {
        textAlign: 'left'
    },
});

const StyledButton = styled(Button)({
    backgroundColor: '#3f51b5',
    color: '#fff',
    '&:hover': {
        backgroundColor: '#303f9f',
    },
    alignSelf: 'flex-end',
});

const FormContainer = styled('form')({
    padding: '20px',
    textAlign: 'right',
});

function EditUserData({ userId }) {
    const [data, setData] = useState(null);
    const [alert, setAlert] = useState({ type: '', message: '' });
    const [href, setHref] = useState('');
    const { token } = useContext(AuthContext);


    const hrefPrefix = process.env.REACT_APP_ENV === 'dev'
        ? `https://dev.scout-review.com/`
        : `https://scout-review.com/`;

    useEffect(() => {
        const fetchUserData = async () => {
            console.log('Fetching user data:', userId);
            if (token) {
                api.getUserData(userId, token)
                    .then(response => {
                        console.log('User data:', response);
                        setData(response);
                        setHref(`${hrefPrefix}${response.staticPagePath}`);
                    })
                    .catch(error => {
                        console.error('Error fetching user data:', error);
                    });
            }
        };

        fetchUserData();
    }, [userId, token]);



    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        console.log('name:', name, 'value:', value);

        setData(prevData => {
            const keys = name.split('.');
            let dataCopy = { ...prevData };
            let current = dataCopy;

            for (let i = 0; i < keys.length - 1; i++) {
                if (!current[keys[i]]) {
                    current[keys[i]] = Number.isInteger(parseInt(keys[i + 1])) ? [] : {};
                }

                if (Number.isInteger(parseInt(keys[i + 1]))) {
                    current[keys[i]] = [...current[keys[i]]];
                } else {
                    current[keys[i]] = { ...current[keys[i]] };
                }

                current = current[keys[i]];
            }
            current[keys[keys.length - 1]] = value;

            return dataCopy;
        });
    };

    const handleDarkThemeChange = (event) => {
        setData({
            ...data,
            reviewPageConfig: {
                ...data.reviewPageConfig,
                "darkTheme": event.target.checked
            }
        });
    };

    const handleMessagesChange = (messages) => {
        setData({
            ...data,
            reviewPageConfig: {
                ...data.reviewPageConfig,
                "messages": messages
            }
        });
    }

    const handleLinksChange = (links) => {
        setData({
            ...data,
            reviewPageConfig: {
                ...data.reviewPageConfig,
                "links": links
            }
        });
    }

    const handleSubscribersChange = (notificationConfig) => {
        setData({
            ...data,
            "notificationConfig": notificationConfig
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        let updatedData = { ...data };


        api.updateUserData(updatedData, token)
            .then(response => {
                setAlert({ type: 'success', message: 'User data updated successfully.' });
                setTimeout(() => setAlert({}), 4000);
                console.log('User data updated:', response.data);
            })
            .catch(error => {
                let errorData = JSON.parse(error.message);
                setAlert({ type: 'error', message: `Error updating user data. ${errorData.message}` });
                setTimeout(() => setAlert({}), 4000);
            });
    };

    const loader = (
        <div className={`container dark-theme loader`}>
            <p className={'scout-title'}>SCOUT</p>
        </div>
    )

    if (!data) {
        return loader;
    }

    return (
        <FormContainer onSubmit={handleSubmit} key={`form-${data.userId}`}>
            {alert.type && (
                <Alert
                    severity={alert.type}
                    style={{ position: 'fixed', top: '20px', right: '20px', zIndex: 9999 }}
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setAlert({});
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                >
                    {alert.message}
                </Alert>
            )}
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <EditUserLogo data={data} StyledTextField={StyledTextField} handleChange={handleChange} token={token} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={1} sm={1} alignContent={'center'} alignItems={'center'}>
                            <a href={href} target="_blank" rel="noopener noreferrer" key={`link-${data.userId}`}>
                                <OpenInNewOutlinedIcon />
                            </a>
                        </Grid>
                        <Grid item xs={11} sm={11}>
                            <StyledTextField key={`sp-${data.userId}`} name="staticPagePath" label="Static Page Path" value={data.staticPagePath} disabled onChange={handleChange} size='small' variant="standard" fullWidth />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={10} sm={9}>
                            <StyledTextField key={`company-name-${data.userId}`} name="reviewPageConfig.company.name" label="Company Name" value={data.reviewPageConfig.company.name} onChange={handleChange} size='small' variant="standard" fullWidth />
                        </Grid>
                        <Grid item xs={2} sm={3}>
                            <StyledTextField key={`language-${data.userId}`} name="language" label="Language" value={data.language} onChange={handleChange} size='small' variant="standard" fullWidth />
                        </Grid>
                    </Grid>

                    <StyledTextField key={`company-desc-${data.userId}`} name="reviewPageConfig.company.description" label="Company Description" value={data.reviewPageConfig.company.description} onChange={handleChange} size='small' variant="standard" fullWidth />
                    <StyledTextField key={`company-addr-${data.userId}`} name="reviewPageConfig.company.address" label="Company Address" value={data.reviewPageConfig.company.address} onChange={handleChange} size='small' variant="standard" fullWidth />
                    <StyledTextField key={`message-config-${data.userId}`} name="messageConfig.template" label="Message Template" value={data.messageConfig.template} onChange={handleChange} size='small' variant="standard" fullWidth />
                    <Grid container spacing={3}>
                        <Grid item xs={4} sm={4}>
                            <Grid container spacing={3} >
                                <Grid item xs={2} sm={2}>
                                    <div style={{ backgroundColor: data.reviewPageConfig.userColor, width: '30px', height: '30px', borderRadius: '15px' }}>
                                    </div>
                                </Grid>
                                <Grid item xs={8} sm={8}>
                                    <StyledTextField key={`user-color-${data.userId}`} name="reviewPageConfig.userColor" label="User Color" value={data.reviewPageConfig.userColor} onChange={handleChange} size='small' variant="standard" fullWidth />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4} sm={4}>
                            <Grid container spacing={3}>
                                <Grid item xs={2} sm={2}>
                                    <div style={{ backgroundColor: data.reviewPageConfig.userGradient[0], width: '30px', height: '30px', borderRadius: '15px' }}>
                                    </div>
                                </Grid>
                                <Grid item xs={8} sm={8}>
                                    <StyledTextField key={`u-g-1-${data.userId}`} name="reviewPageConfig.userGradient.0" label="User Gradient From" value={data.reviewPageConfig.userGradient[0]} onChange={handleChange} size='small' variant="standard" fullWidth />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4} sm={4}>
                            <Grid container spacing={3}>
                                <Grid item xs={2} sm={2}>
                                    <div style={{ backgroundColor: data.reviewPageConfig.userGradient[1], width: '30px', height: '30px', borderRadius: '15px' }}>
                                    </div>
                                </Grid>
                                <Grid item xs={8} sm={8}>
                                    <StyledTextField key={`u-g-2-${data.userId}`} name="reviewPageConfig.userGradient.1" label="User Gradient To" value={data.reviewPageConfig.userGradient[1]} onChange={handleChange} size='small' variant="standard" fullWidth />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            <FormControlLabel
                                control={<Checkbox
                                    key={`dark-theme-${data.userId}`}
                                    checked={data.reviewPageConfig.darkTheme}
                                    onChange={handleDarkThemeChange}
                                    name="reviewPageConfig.darkTheme"
                                />}
                                label="Dark Theme"
                            />
                        </Grid>

                    </Grid>
                    <Grid container direction="row" spacing={1} mt={1}>
                        <Grid item xs={5}>
                            <Grid item xs={4}>
                                <StyledTextField key={`rating-${data.userId}`}
                                    name="reviewPageConfig.minRating"
                                    label="Min Rating"
                                    value={data.reviewPageConfig.minRating}
                                    onChange={handleChange}
                                    size='small'
                                    variant="standard"
                                    fullWidth
                                    style={{ margin: 'auto' }}
                                />
                            </Grid>
                            <Grid container direction="column" spacing={3}>
                                <Grid item xs={6}>
                                    <StyledTextField
                                        key={`impoveRating-${data.userId}`}
                                        size='small'
                                        label="Rating to improve service"
                                        variant="standard"
                                        name="reviewPageConfig.ratingToImproveService"
                                        fullWidth
                                        value={data.reviewPageConfig.ratingToImproveService}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <StyledTextField
                                        key={`callbackPhone-${data.userId}`}
                                        label="Phone Number for callback"
                                        type="tel"
                                        size='small'
                                        variant="standard"
                                        name="reviewPageConfig.phoneNumberForCallback"
                                        fullWidth
                                        value={data.reviewPageConfig.phoneNumberForCallback} // The value of the field is bound to formState.phoneNumber
                                        onChange={handleChange} // formState.phoneNumber is updated when the field value changes
                                    />
                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid item xs={4}>
                            <StyledTextField
                                key={`seconds-${data.userId}`}
                                name="reviewPageConfig.autoRedirect"
                                label="Autoredirect seconds"
                                value={data.reviewPageConfig.autoRedirect}
                                onChange={handleChange}
                                size='small'
                                variant="standard"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <EditReviewPageMessages key={`erp-${data.userId}`} messages={data.reviewPageConfig.messages} onDataChange={handleMessagesChange} />
                </Grid>
            </Grid>
            <br />
            <Divider flexItem />
            <br />
            <Grid container spacing={3}>
                <Grid item xs={6} sm={6}>
                    <EditLinks key={`edit-links-${data.userId}`} userLinks={data.reviewPageConfig.links} handleUpdate={handleLinksChange} />
                </Grid>
                <Grid item xs={6} sm={6}>
                    <EditSubscribers key={`edit-subscribers-${data.userId}`} notificationConfig={data.notificationConfig} updateConfig={handleSubscribersChange} />
                </Grid>
            </Grid>
            <StyledButton type="submit" variant="outlined">Save</StyledButton>
        </FormContainer>
    );
};

export default EditUserData;