import React, { useEffect, useState, useContext } from 'react';
import ReactDOM from 'react-dom/client';
import './global-styles.scss';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import MainPage from "./components/pages/main-page/main-page";
import ErrorPage from "./components/pages/error-page/error-page";
import LandingPage from "./components/pages/landing-page/landing-page";
import AdminPage from "./components/pages/admin/admin-page";
import { Button } from '@mui/material';
import { AuthContext, AuthProvider } from './auth-context';
import { jwtDecode } from 'jwt-decode';

const PrivateRoute = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const accessToken = localStorage.getItem('access_token');

        if (accessToken) {
            try {
                const { exp, 'cognito:groups': groups } = jwtDecode(accessToken);
                if (Date.now() >= exp * 1000) {
                    setIsAuthenticated(false);
                    navigate('/login');
                } else {
                    setIsAuthenticated(true);
                    setIsAdmin(groups?.includes('admins'));
                }
            } catch (error) {
                console.error('Error decoding access token:', error);
                setIsAuthenticated(false);
                navigate('/login');
            }
        } else {
            setIsAuthenticated(false);
            navigate('/login');
        }
    }, [navigate]);

    if (isAuthenticated === null) {
        return null; // or a loading spinner
    }

    if (!isAdmin) {
        return <div>You are not authorized to view this page</div>;
    }

    return children;
};

const CallbackPage = () => {
    const navigate = useNavigate();
    const { setToken } = useContext(AuthContext);

    useEffect(() => {
        const hashParams = new URLSearchParams(window.location.hash.substring(1));
        hashParams.forEach((value, key) => {
            console.log(key, value);
        });
        const id_token = hashParams.get('id_token');
        const accessToken = hashParams.get('access_token');

        if (id_token && accessToken) {
            localStorage.setItem('id_token', id_token);
            localStorage.setItem('access_token', id_token);
            setToken(id_token);

            const decodedToken = jwtDecode(id_token);
            console.log('Decoded token:', decodedToken);

            navigate('/admin');
        } else {
            navigate('/login');
        }
    }, [navigate, setToken]);

    return null;
};

const LoginPage = () => {
    const handleLogin = () => {
        const cognitoUrl = `https://login.${process.env.REACT_APP_ENV}.scout-review.com/login?client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=${process.env.REACT_APP_CALLBACKURL}`;
        window.location.href = cognitoUrl;
    };

    return (
        <Button onClick={handleLogin} variant='contained' size='large' style={{ margin: "40px" }}>Log in</Button>
    );
};

function AdminPageWrapper() {
    const { setToken } = useContext(AuthContext);
    const navigate = useNavigate();
  
    return <AdminPage setToken={setToken} navigate={navigate} />;
  }

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <AuthProvider>
        <Router>
            <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/admin" element={<PrivateRoute><AdminPageWrapper /></PrivateRoute>} />
                <Route path="/m/:messageId" element={<MainPage />} />
                <Route path="/:companyName" element={<MainPage />} />
                <Route path="/error" element={<ErrorPage />} />
                <Route path="/callback" element={<CallbackPage />} />
                <Route path="/login" element={<LoginPage />} />
            </Routes>
        </Router>
    </AuthProvider>
);

