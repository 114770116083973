import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const QuestionContainer = styled.div`
  padding: 20px;
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
`;

const OptionLabel = styled.label`
  display: flex;
  align-items: center; /* Вертикальне вирівнювання */
  justify-content: center; /* Горизонтальне вирівнювання */
  margin-bottom: 10px;
  background-color: #f5f5f5;
  height: 40px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #e0e0e0;
  }
`;


const OptionInput = styled.input`
  // margin-right: 10px;
`;

function QuestionComponent({ question, handleAnswer }) {
  const [selectedOptions, setSelectedOptions] = useState([]);
  useEffect(() => {
    handleAnswer(question, selectedOptions);
  }, [selectedOptions]);

  const handleOptionChange = (option) => {
    if (question.multipleAnswers) {
      setSelectedOptions(prevState =>
        prevState.includes(option)
          ? prevState.filter(opt => opt !== option)
          : [...prevState, option]
      );
    } else {
      setSelectedOptions([option]);
    }
  };

  return (
    <QuestionContainer>
      <h2>{question.text}</h2>
      {question.subtext && <p>{question.subtext}</p>}
      {question.options.map(option => (
        <OptionLabel key={option}>
          <OptionInput
            type={question.multipleAnswers ? "checkbox" : "radio"}
            value={option}
            checked={selectedOptions.includes(option)}
            onChange={() => handleOptionChange(option)}
          />
          {option}
        </OptionLabel>
      ))}
    </QuestionContainer>
  );
}

export default QuestionComponent;