import React, { useState, useEffect } from 'react';
import { Box, TextField, List, ListItemText, ListItemButton, Avatar, AppBar, Tabs, Tab, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import { differenceInDays, parse, format } from 'date-fns';
import EditUserData from './edit-user-data';
import ReviewsTab from './reviews-tab';
import CustomerMessaging from './customer-messaging';
import api from '../../../services/admin-api';
import { useContext } from "react";
import { AuthContext } from "../../../auth-context";


const UsersTab = () => {
  const [users, setUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const { token } = useContext(AuthContext);

  const fetchUsers = async () => {
    if (token) {
      api.getAllUsersData(token)
        .then(response => {
          setUsers(response);
        })
        .catch(error => {
          console.error('Error fetching user data:', error);
        });
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [token]);

  const handleAddUser = async () => {
    try {
      const newUser = await api.createDefaultUser(token);
      // Add logic to handle the new user data
      fetchUsers();
    } catch (error) {
      console.error('Failed to create new user:', error);
    }
  };

  const handleUserClick = (userId) => {
    setSelectedUserId(userId);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box display="flex" padding={"10px"}>
      <Box width="15%">
        <TextField label="Search" fullWidth />
        <Box marginTop={2} marginBottom={1}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleAddUser}
          >
            Add default user
          </Button>
        </Box>
        <List>
          {users.map((user) => {
            const subscriptionEndDate = user.balances.subscriptionEndDate
              ? parse(user.balances.subscriptionEndDate, 'dd/MM/yyyy', new Date())
              : new Date();

            const daysLeft = differenceInDays(subscriptionEndDate, new Date());
            console.log('daysLeft:', daysLeft);
            console.log('subscriptionEndDate:', subscriptionEndDate);

            return (
              <div key={user.userId}>
                {daysLeft <= 14 && (
                  <Box color={daysLeft >= 0 ? "warning.main" : "error.main"} display="flex" flexDirection="column" alignItems="center" padding={1}>
                    {daysLeft >= 0 ? <WarningIcon /> : <ErrorIcon />}
                    <Box marginLeft={1}>
                      {daysLeft >= 0 ? `Subscription is ending soon! Ends on ${format(subscriptionEndDate, 'dd-MM-yyyy')}` : 'Your subscription has ended!'}
                    </Box>
                  </Box>
                )}
                <ListItemButton
                  selected={selectedUserId === user.userId}
                  onClick={() => handleUserClick(user.userId)}
                >
                  <Avatar alt={"N/A"} src={user.reviewPageConfig.logoUrl || ''} />
                  <ListItemText primary={user.staticPagePath || 'N/A'}
                    secondary={`Reviews: ${user.reviewStatistic.goodReviewCounter} 👍 ${user.reviewStatistic.badReviewCounter} 👎`} />
                </ListItemButton>
              </div>
            );
          })}
        </List>
      </Box>
      <Box width="1%"></Box>
      <Box width="84%">
        {selectedUserId && (
          <Box>
            <AppBar position="static">
              <Tabs value={selectedTab} onChange={handleTabChange}>
                <Tab label="Config" style={selectedTab === 0 ? { color: 'white', fontWeight: 600 } : {}} />
                <Tab label="Reviews" style={selectedTab === 1 ? { color: 'white', fontWeight: 600 } : {}} />
                <Tab label="Statistics" style={selectedTab === 2 ? { color: 'white', fontWeight: 600 } : {}} />
                <Tab label="Messaging" style={selectedTab === 3 ? { color: 'white', fontWeight: 600 } : {}} />
              </Tabs>
            </AppBar>
            {selectedTab === 0 && <EditUserData userId={selectedUserId} />}
            {selectedTab === 1 && <ReviewsTab userId={selectedUserId} />}
            {selectedTab === 2 && <div>Statistics</div>}
            {selectedTab === 3 && <CustomerMessaging userId={selectedUserId} />}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default UsersTab;
