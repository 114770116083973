export default function LandingPage() {
        return (
            <>
                <iframe
                    title="HTML Page"
                    src={"/scout-landing/index.html"}
                    style={{ width: '100%', height: '100vh', border: 'none', overflow: 'hidden'}}
                />
            </>

        );
}