import React, {useState} from 'react';

const StarRating = ({onStarClick, selectedRate, userColor, userGradient, previewMode, handleAnswer, question}) => {
    // const [hoveredIndex, setHoveredIndex] = useState(-1);
    const [selectedStars, setSelectedStars] = useState([]);


    const linearGradient = (
        <linearGradient id="paint0_linear_202_310" x1="38.004" y1="17.9996" x2="0.00390681" y2="17.9996" gradientUnits="userSpaceOnUse">
            <stop stopColor={userGradient[0]} />
            <stop offset="1" stopColor={userGradient[1]} />
        </linearGradient>
    );

    const starEmpty = (
        <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="bi:star-fill" clipPath="url(#clip0_202_123)">
                <path
                    id="Vector"
                    d="M 7.791 35.444 L 7.791 35.445 C 7.766 35.591 7.822 35.699 7.9 35.762 C 7.941 35.794 7.98 35.808 8.011 35.812 C 8.037 35.815 8.072 35.813 8.122 35.788 L 18.543 30.43 L 19 30.195 L 19.457 30.43 L 29.885 35.788 L 18.977 30.182 C 18.977 30.182 8.094 36.354 7.791 35.444 Z M 7.791 35.444 L 9.763 24.21 L 9.855 23.683 M 7.791 35.444 L 9.855 23.683 M 9.855 23.683 L 9.467 23.314 M 9.855 23.683 L 9.467 23.314 M 9.467 23.314 L 1.101 15.344 L 1.101 15.343 C 1.013 15.259 0.979 15.135 1.019 15 C 1.059 14.866 1.141 14.813 1.222 14.801 L 1.223 14.801 M 9.467 23.314 L 1.223 14.801 M 1.223 14.801 L 12.856 13.148 L 13.373 13.074 M 1.223 14.801 L 13.373 13.074 M 13.373 13.074 L 13.608 12.608 M 13.373 13.074 L 13.608 12.608 M 13.608 12.608 L 18.795 2.332 L 13.608 12.608 Z M 24.634 13.074 L 24.398 12.608 L 19.212 2.332 C 19.178 2.267 19.141 2.234 19.112 2.216 C 19.08 2.197 19.042 2.187 19.003 2.187 C 18.964 2.187 18.927 2.197 18.895 2.216 C 18.866 2.234 18.828 2.266 18.795 2.332 L 24.634 13.074 Z M 24.634 13.074 L 25.15 13.148 M 24.634 13.074 L 25.15 13.148 M 25.15 13.148 L 36.783 14.801 L 36.785 14.801 M 25.15 13.148 L 36.785 14.801 M 36.785 14.801 C 36.866 14.813 36.947 14.866 36.987 14.999 C 37.027 15.134 36.993 15.258 36.904 15.343 M 36.785 14.801 L 36.904 15.343 M 36.904 15.343 L 28.539 23.314 L 28.151 23.683 M 36.904 15.343 L 28.151 23.683 M 28.151 23.683 L 28.244 24.21 M 28.151 23.683 L 28.244 24.21 M 28.244 24.21 L 30.215 35.444 L 30.215 35.445 M 28.244 24.21 L 30.215 35.445 M 30.215 35.445 C 30.241 35.591 30.185 35.699 30.106 35.762 M 30.215 35.445 L 30.106 35.762 M 30.106 35.762 C 30.066 35.794 30.027 35.808 29.996 35.812 M 30.106 35.762 L 29.996 35.812 M 29.996 35.812 C 29.969 35.815 29.935 35.813 29.885 35.788 L 29.996 35.812 Z"
                    stroke={`${userColor}`}
                    strokeWidth="2"
                />
            </g>
        </svg>
    );

    const starFilled = (
        <svg xmlns="http://www.w3.org/2000/svg" width="38" height="36" viewBox="0 0 38 36">
            <path d="M8.57921 35.6773C7.66246 36.1476 6.62221 35.3235 6.80746 34.2713L8.77871 23.0376L0.411583 15.0671C-0.369792 14.3213 0.0363331 12.9581 1.08371 12.8108L12.7165 11.1578L17.9035 0.881211C18.3713 -0.0450391 19.6372 -0.0450391 20.1051 0.881211L25.2921 11.1578L36.9248 12.8108C37.9722 12.9581 38.3783 14.3213 37.5946 15.0671L29.2298 23.0376L31.2011 34.2713C31.3863 35.3235 30.3461 36.1476 29.4293 35.6773L19.0007 30.3193L8.57921 35.6773Z"
                  fill="url(#paint0_linear_202_310)"/>
                <defs>
                    {linearGradient}
                </defs>
        </svg>

    );

    const handleStarClick = (index) => {
        const newSelectedStars = [];

        // Додаємо обрані зірки та всі перед ними
        for (let i = 0; i <= index; i++) {
            newSelectedStars.push(i);
        }

        // Оновлюємо стан обраних зірок
        setSelectedStars(newSelectedStars);

        // Викликаємо функцію onStarClick з номером обраної зірки
        if (onStarClick) {
            onStarClick(index);
        }

        if (handleAnswer) {
            handleAnswer(question, index);
        }
    };

    const stars = Array.from({length: 5}, (_, index) => {
        return (
            previewMode ?
                <div
                    key={index}
                    className={'star'}
                >
                    {(index <= selectedRate) ? starFilled : starEmpty}
                </div> :
                <div
                    key={index}
                    // onMouseEnter={() => setHoveredIndex(index)}
                    // onMouseLeave={() => setHoveredIndex(-1)}
                    onClick={() => handleStarClick(index)}
                    className={'star'}
                >
                    {
                        // index <= hoveredIndex ||
                        selectedStars.includes(index) ? starFilled : starEmpty}
                </div>
        )
    });

    return (<>
            <div className={'star-container'}>{stars}</div>
    </>

    )


};

export default StarRating;
