import React, { useState, useEffect } from 'react';
import { TextField, Switch, FormControlLabel, Select, MenuItem, IconButton, Checkbox, Box, Grid } from '@mui/material';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';



const EditReviewPageMessages = ({ messages, onDataChange }) => {
    const [data, setData] = useState(messages);
    const [tempData, setTempData] = useState([]);

    useEffect(() => {
        setData(messages);
    }, [messages]);

    useEffect(() => {
        onDataChange(data);
    }, [data, onDataChange]);

    const handleChange = (event) => {
        setData({
            ...data,
            [event.target.name]: event.target.value
        });
    };

    const handleMessageDataChange = (event) => {
        const { name, value } = event.target;
        const keys = name.split('.');
        let newData = { ...data };

        newData[keys[0]][keys[1]] = value;

        setData(newData);
    };

    const handleSwitchChange = (event) => {
        if (event.target.checked) {
            let temp = [...tempData];
            setTempData({ ...data.initial });
            setData({
                ...data,
                initial: null,
                questions: temp || [],
            });
        } else {
            let temp = { ...tempData }
            setTempData([...data.questions]);
            setData({
                ...data,
                initial: temp || { message: '', subMessage: '' },
                questions: null,
            });
        }
    };

    const handleQuestionChange = (event, index) => {
        const newQuestions = [...data.questions];
        newQuestions[index][event.target.name] = event.target.value;
        setData({
            ...data,
            questions: newQuestions,
        });
    };

    const handleCheckboxChange = (event, index) => {
        const newQuestions = [...data.questions];
        newQuestions[index][event.target.name] = event.target.checked;
        setData({
            ...data,
            questions: newQuestions,
        });
    };

    const handleQuestionTypeChange = (event, index) => {
        const { name, value } = event.target;
        setData(data => {
            const newQuestions = [...data.questions];
            if (name === 'type' && value === 'multiple-choice' && !newQuestions[index].options) {
                newQuestions[index].options = [];
            }
            newQuestions[index].type = value;
            return { ...data, questions: newQuestions };
        });
    };

    const handleOptionChange = (event, questionIndex, optionIndex) => {
        const newQuestions = [...data.questions];
        newQuestions[questionIndex].options[optionIndex] = event.target.value;
        setData({
            ...data,
            questions: newQuestions,
        });
    };

    const handleAddOption = (questionIndex) => {
        const newQuestions = [...data.questions];
        newQuestions[questionIndex].options.push('');
        setData({
            ...data,
            questions: newQuestions,
        });
    };

    const handleRemoveOption = (questionIndex, optionIndex) => {
        const newQuestions = [...data.questions];
        newQuestions[questionIndex].options.splice(optionIndex, 1);
        setData({
            ...data,
            questions: newQuestions,
        });
    };

    const handleAddQuestion = () => {
        const newQuestions = [...data.questions, { type: 'rating', text: '', subtext: '', required: false, scale: 5, options: [], multipleAnswers: false, placeholder: '' }];
        setData({
            ...data,
            questions: newQuestions,
        });
    };

    const handleRemoveQuestion = (index) => {
        const newQuestions = [...data.questions];
        newQuestions.splice(index, 1);
        setData({
            ...data,
            questions: newQuestions,
        });
    };

    return (
        <>
            <FormControlLabel
                control={<Switch checked={!!data.questions} onChange={handleSwitchChange} />}
                label="Use Questions"
                size='small' variant="standard"
            />
            <Box style={{ maxHeight: '400px', overflow: 'auto' }} >
                {data.questions ? (
                    <>
                        {data.questions.map((question, index) => (
                            <div key={index} className='container questionContainer' style={{ position: 'relative' }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={3} sm={3}>
                                        <Select name="type" value={question.type} onChange={(event) => handleQuestionTypeChange(event, index)} size='small' variant="standard" fullWidth>
                                            <MenuItem value="rating">Rating</MenuItem>
                                            <MenuItem value="multiple-choice">Multiple Choice</MenuItem>
                                            <MenuItem value="text">Text</MenuItem>
                                        </Select>
                                        <FormControlLabel
                                            control={<Checkbox checked={!!question.required} onChange={(event) => handleCheckboxChange(event, index)} name="required" />}
                                            label="Required"
                                            size='small' variant="standard"
                                        />
                                    </Grid>
                                    <Grid item xs={9} sm={9}>
                                        <TextField name="text" label="Text" value={question.text} onChange={(event) => handleQuestionChange(event, index)} size='small' variant="standard" fullWidth />
                                        <TextField name="subtext" label="Subtext" value={question.subtext} onChange={(event) => handleQuestionChange(event, index)} size='small' variant="standard" fullWidth />
                                    </Grid>
                                </Grid>
                                {question.type === 'multiple-choice' && (
                                    <>
                                        <FormControlLabel
                                            control={<Checkbox checked={question.multipleAnswers} onChange={(event) => handleCheckboxChange(event, index)} name="multipleAnswers" />}
                                            label="Multiple Answers"
                                            size='small' variant="standard"
                                        />
                                        <Box display="flex" alignItems="flex-start" flexDirection={'row'} flexWrap={'wrap'} justifyContent={'flex-start'} alignContent={'flex-start'} gap={'10px'} >
                                            {(question.options || []).map((option, optionIndex) => (
                                                <Grid container spacing={3} key={optionIndex} minWidth={'25%'} maxWidth={'30%'} >
                                                    <Grid item xs={2} sm={2}>
                                                        <IconButton onClick={() => handleRemoveOption(index, optionIndex)} size='small' variant="standard">
                                                            <RemoveCircleOutline color='error' />
                                                        </IconButton>
                                                    </Grid>
                                                    <Grid item xs={10} sm={10}>
                                                        <TextField name="options" value={option} onChange={(event) => handleOptionChange(event, index, optionIndex)} size='small' variant="standard" />
                                                    </Grid>
                                                </Grid>
                                            ))}
                                        </Box>

                                        <IconButton onClick={() => handleAddOption(index)}>
                                            <AddCircleOutline color='success' />
                                        </IconButton>
                                    </>
                                )}
                                {question.type === 'text' && (
                                    <TextField name="placeholder" label="Placeholder" value={question.placeholder} onChange={(event) => handleQuestionChange(event, index)} size='small' variant="standard" fullWidth />
                                )}
                                {question.type === 'rating' && (
                                    <TextField name="scale" label="Scale" value={question.scale} onChange={(event) => handleQuestionChange(event, index)} size='small' variant="standard" fullWidth />
                                )}
                                <IconButton onClick={() => handleRemoveQuestion(index)} style={{ display: 'block', position: 'absolute', top: '5px', right: '5px' }} >
                                    <RemoveCircleOutline color='error' fontSize='1.5em' />
                                </IconButton>
                            </div>
                        ))}
                        <IconButton onClick={handleAddQuestion}>
                            <AddCircleOutline color='success' />
                        </IconButton>
                    </>
                ) : (
                    <>
                        <TextField name="initial.message" label="Initial Message" value={data.initial.message} onChange={handleMessageDataChange} size='small' variant="standard" fullWidth />
                        <TextField name="initial.subMessage" label="Initial SubMessage" value={data.initial.subMessage} onChange={handleMessageDataChange} size='small' variant="standard" fullWidth />
                    </>
                )}
            </Box >
            <br />
            <br />
            <Grid container spacing={3}>
                <Grid item xs={4} sm={4}>
                    <TextField name="afterGoodReview.message" label="After Good Review Message" value={data.afterGoodReview.message} onChange={handleMessageDataChange} size='small' variant="standard" fullWidth />
                    <TextField name="afterGoodReview.subMessage" label="After Good Review SubMessage" value={data.afterGoodReview.subMessage} onChange={handleMessageDataChange} size='small' variant="standard" fullWidth />
                </Grid>
                <Grid item xs={4} sm={4}>
                    <TextField name="afterBadReview.message" label="After Bad Review Message" value={data.afterBadReview.message} onChange={handleMessageDataChange} size='small' variant="standard" fullWidth />
                    <TextField name="afterBadReview.subMessage" label="After Bad Review SubMessage" value={data.afterBadReview.subMessage} onChange={handleMessageDataChange} size='small' variant="standard" fullWidth />
                </Grid>
                <Grid item xs={4} sm={4}>
                    <TextField name="afterComplaintLeft.message" label="After Complaint Left Message" value={data.afterComplaintLeft.message} onChange={handleMessageDataChange} size='small' variant="standard" fullWidth />
                    <TextField name="afterComplaintLeft.subMessage" label="After Complaint Left SubMessage" value={data.afterComplaintLeft.subMessage} onChange={handleMessageDataChange} size='small' variant="standard" fullWidth />
                </Grid>
            </Grid>
            <br />
            <br />
            <Grid container spacing={3}>
                <Grid item xs={4} sm={4}>
                    <TextField name="rateButton" label="Rate Button" value={data.rateButton} onChange={handleChange} size='small' variant="standard" fullWidth />
                </Grid>
                <Grid item xs={4} sm={4}>
                    <TextField name="sendComplaintButton" label="Send Complaint Button" value={data.sendComplaintButton} onChange={handleChange} size='small' variant="standard" fullWidth />
                </Grid>
                <Grid item xs={4} sm={4}>
                    <TextField name="complaintPlaceholder" label="Complaint Placeholder" value={data.complaintPlaceholder} onChange={handleChange} size='small' variant="standard" fullWidth />
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={4} sm={4}>
                    <TextField name="afterImproveReview.message" label="After Improve Review Message" value={data.afterImproveReview.message} onChange={handleMessageDataChange} size='small' variant="standard" fullWidth />
                    <TextField name="afterImproveReview.subMessage" label="After Improve Review SubMessage" value={data.afterImproveReview.subMessage} onChange={handleMessageDataChange} size='small' variant="standard" fullWidth />
                </Grid>
            </Grid>


        </>
    );
};

export default EditReviewPageMessages;