import React, { useState, useEffect } from 'react';
import { TextField, IconButton } from '@mui/material';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';

const EditSubscribers = ({ notificationConfig, updateConfig }) => {
    const [emailSubscribers, setEmailSubscribers] = useState(notificationConfig.emailSubscribers || []);
    const [telegramSubscribers, setTelegramSubscribers] = useState(notificationConfig.telegramSubscribers || []);

    useEffect(() => {
        setEmailSubscribers(notificationConfig.emailSubscribers || []);
        setTelegramSubscribers(notificationConfig.telegramSubscribers || []);
    }, [notificationConfig]);

    useEffect(() => {
        updateConfig({
            ...notificationConfig,
            emailSubscribers,
            telegramSubscribers
        });
    }, [emailSubscribers, telegramSubscribers]);

    const addEmailSubscriber = () => {
        setEmailSubscribers([...emailSubscribers, '']);
    };

    const removeEmailSubscriber = (index) => {
        setEmailSubscribers(emailSubscribers.filter((_, i) => i !== index));
    };

    const handleEmailChange = (index, value) => {
        const newEmailSubscribers = [...emailSubscribers];
        newEmailSubscribers[index] = value;
        setEmailSubscribers(newEmailSubscribers);
    };

    const removeTelegramSubscriber = (index) => {
        setTelegramSubscribers(telegramSubscribers.filter((_, i) => i !== index));
    };

    // ...

    return (
        <div className='container linksContainer' style={{ 'flexWrap': 'wrap' }}>
            {emailSubscribers.map((email, index) => (
                <div key={index}>
                    <IconButton onClick={() => removeEmailSubscriber(index)} >
                        <RemoveCircleOutline color='error' fontSize='1.5em' />
                    </IconButton>
                    <TextField name="email" label="Email Subscriber" value={email} onChange={(e) => handleEmailChange(index, e.target.value)} size='small' variant="standard" />
                </div>
            ))}
            <IconButton onClick={addEmailSubscriber}>
                <AddCircleOutline color='success' fontSize='2.5rem' size='big'/>
            </IconButton>
            <br/>
            {telegramSubscribers.map((telegram, index) => (
                <div key={index}>
                    <IconButton onClick={() => removeTelegramSubscriber(index)} >
                        <RemoveCircleOutline color='error' fontSize='1.5em' />
                    </IconButton>
                    <TextField name="telegram" label="Telegram Subscriber" value={telegram} disabled size='small' variant="standard" fullWidth />
                </div>
            ))}
        </div>
    );
}

export default EditSubscribers;