import React, { useState, useContext } from 'react';
import { Grid, TextField, Switch, FormControlLabel, Button } from '@mui/material';
import api from '../../../services/admin-api';
import { AuthContext } from "../../../auth-context";

function CustomerMessaging({ userId }) {
    const [phoneNumbers, setPhoneNumbers] = useState();
    const [isViber, setIsViber] = useState(false);
    const { token } = useContext(AuthContext);

    const handleSubmit = async () => {
        // Check if phoneNumbers is not empty
        if (!phoneNumbers) {
            console.error('Error: No phone numbers provided');
            return;
        }

        // Convert phone numbers to JSON and do something with it
        const phoneNumbersArray = phoneNumbers.trim().split('\n').map(phone => phone.trim());

        // Handle the case where phoneNumbers is an empty string
        if (phoneNumbersArray.length === 1 && phoneNumbersArray[0] === '') {
            console.error('Error: No phone numbers provided');
            return;
        }

        // Create an object in the desired format
        const messageData = {
            to: phoneNumbersArray,
            userId: userId,
            channel: isViber ? 'viber' : 'sms'
        };

        try {
            // Call the sendMessageToCustomers function from the api
            const response = await api.sendMessageToCustomers(messageData, token);

            // Handle the response
            if (response.ok) {
                console.log('Data sent successfully');
            } else {
                console.error('Error sending data:', response.statusText);
            }
        } catch (error) {
            // Handle errors
            console.error('Error sending data:', error.message);
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} style={{ marginTop: '20px' }}>
                <TextField
                    label="Phone Numbers"
                    multiline
                    rows={4}
                    variant="outlined"
                    value={phoneNumbers}
                    onChange={(e) => setPhoneNumbers(e.target.value)}
                    helperText="Enter phone numbers separated by commas"
                    fullWidth
                    inputProps={{ style: { textAlign: 'left' } }}
                />
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    control={<Switch checked={isViber} onChange={() => setIsViber(prev => !prev)} />}
                    label={isViber ? "Viber" : "SMS"}
                />
            </Grid>
            <Grid item xs={12}>
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                    Submit
                </Button>
            </Grid>
        </Grid>
    );
}

export default CustomerMessaging;