import React, { useState, useEffect } from 'react';
import { Grid, Avatar, IconButton, Tooltip } from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import api from '../../../services/admin-api';
import config from '../../../config';

const EditUserLogo = ({ data, StyledTextField, handleChange, token }) => {
    const [logo, setLogo] = useState(data.reviewPageConfig.logoUrl);
    const [logoName, setLogoName] = useState(data.reviewPageConfig.logoUrl);

    const [hover, setHover] = useState(false);

    useEffect(() => {
        setLogo(logoName);
    }, [logoName]);

    const handleLogoUpload = async (event) => {
        const file = event.target.files[0];


        if (file) {
            const newLogoName = `${config.hostUrl.replace('-api', '')}/images/logos/` + data.staticPagePath + file.name.substring(file.name.lastIndexOf('.'));
            const formData = new FormData();
            setLogo(URL.createObjectURL(file));
            setLogoName(newLogoName);
            handleChange({ target: { name: "reviewPageConfig.logoUrl", value: newLogoName } });

            console.log(file.name);

            formData.append('file', file);

            try {
                const response = await api.uploadUserLogo(formData, token, data.staticPagePath);
                console.log(response);
            } catch (error) {
                console.error('Error uploading logo:', error);
            }

        }
    };
    return (
        <Grid container spacing={3} direction={"row"} alignItems="center">
            <Grid
                item
                xs={1}
                sm={1}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                style={{
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingLeft: '25px',
                    paddingTop: '15px'
                }}
            >
                <Avatar
                    key={`avatar-${data.userId}`}
                    alt='N/A'
                    src={logo}
                    style={{ opacity: hover ? 0.5 : 1 }}
                />

                {hover && (
                    <Tooltip title="Change logo">
                        <label htmlFor="icon-button-file">
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                position: 'absolute',
                                top: '15',
                                bottom: '0',
                                left: '25px',
                                right: '0'
                            }}>
                                <IconButton color="primary" component="span">
                                    <PhotoCamera />
                                </IconButton>
                            </div>
                        </label>
                    </Tooltip>
                )}
                <input
                    accept="image/*"
                    id="icon-button-file"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={handleLogoUpload}
                />
            </Grid>
            <Grid item xs={11} sm={11}>
                <StyledTextField
                    key={`logo-url-${data.userId}`}
                    name="reviewPageConfig.logoUrl"
                    label="Logo URL"
                    value={logoName}
                    onChange={(event) => setLogoName(event.target.value)}
                    size='small'
                    variant="standard"
                    fullWidth
                />
            </Grid>
        </Grid>
    );
};

export default EditUserLogo;


