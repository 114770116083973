import config from "../config"

const callWithData = async (url, method, data, token) => {
    const response = await fetch(`${config.hostUrl}${url}`, {
        method: method,
        credentials: "include",
        headers: {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(data)
    });
    if (!response.ok) {
        await handleError(response);
    }
    return response
}

const call = async (url, method, token) => {
    const response = await fetch(`${config.hostUrl}${url}`, {
        method: method,
        credentials: "include",
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    if (!response.ok) {
        handleError(response);
    }
    return response
}

const handleError = async (response) => {
    let errorData;
    try {
        errorData = await response.json();
    } catch (e) {
        errorData = { message: 'Error parsing server response' };
    }
    throw new Error(JSON.stringify(errorData));
}

const api = {
    getAllUsersData: async (token) => {
        const json = (await call(`/admin/users`, "GET", token)).json();
        return json
    },
    updateUserData: async (userData, token) => {
        return await callWithData(`/admin/user`, "POST", userData, token);
    },
    getUserData: async (userId, token) => {
        const json = (await call(`/admin/user/${userId}`, "GET", token)).json();
        return json
    },
    getUserReviews: async (userId, token) => {
        const json = (await call(`/admin/reviews/${userId}`, "GET", token)).json();
        return json
    },
    sendMessageToCustomers: async (userData, token) => {
        return await callWithData(`/admin/messagio/send`, "POST", userData, token);
    },
    createDefaultUser: async (token) => {
        const json = (await call(`/admin/default-user`, "POST", token)).json;
        return json
    },
    uploadUserLogo: async (formData, token, path) => {
        const response = await fetch(`${config.hostUrl}${'/admin/user/upload-logo'}?path=${encodeURIComponent(path)}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            body: formData,
        });

        if (!response.ok) {
            const errorData = await response.json();
            handleError(errorData);
            throw new Error('Error uploading logo');
        }
    }


}

export default api;