import QuestionComponent from "../../shared/questions/question-with-answers"
import TextQuestionComponent from "../../shared/questions/question-with-text-input";
import ScaleQuestionComponent from "./question-with-scale";

function Survey({questions, handleAnswer, userColor, userGradient}) {
    
    return (
        <div className="surveyContainer">
            {questions.map((question) => {
                switch (question.type) {
                    case "rating":
                        return <ScaleQuestionComponent key={question.text} question={question} handleAnswer={handleAnswer} userColor={userColor} userGradient={userGradient} />;
                    case "multiple-choice":
                        return <QuestionComponent key={question.text} question={question} handleAnswer={handleAnswer} />;
                    case "text":
                        return <TextQuestionComponent key={question.text} question={question} handleAnswer={handleAnswer} />;
                    default:
                        return null;
                }
            })}
        </div>
    );
}

export default Survey;