import config from "../config"

const callWithData = async (url, method, data) => {
    const response = await fetch(`${config.hostUrl}${url}`, {
        method: method,
        credentials: "include",
        headers: {
            'Content-Type': "application/json"
        },
        body: JSON.stringify(data)
    });
    return response
}

const callWithFormData = async (url, method, data) => {
    const response = await fetch(`${config.hostUrl}${url}`, {
        method: method,
        credentials: "include",
        body: data
    });
    return response
}



const call = async (url, method) => {
    const response = await fetch(`${config.hostUrl}${url}`, {
        method: method,
        credentials: "include"
    });
    return response
}

const api = {
    getReviewPageForMessageId: async (messageId) => {
        const json = (await call(`/reviewPage/${messageId}`, "GET")).json();
        return json
    },
    getReviewPageForCompanyName: async (companyName) => {
        const json = (await call(`/reviewPage/s/${companyName}`, "GET")).json();
        return json
    },
    submitReview: async (messageId, rating) => {
        return await call(`/reviewPage/rating/${messageId}/${rating}`, "POST");
    },
    submitReviewWithAnswers: async (messageId, answers) => {
        return await callWithData(`/reviewPage/rating/${messageId}/answers`, "POST", answers);
    },
    submitComplaint: async (messageId, data) => {
        return await callWithFormData(`/reviewPage/complaint/${messageId}`, "POST", data);
    },
    submitExternalLinkFollow: async (messageId, name) => {
        return await callWithData(`/reviewPage/externalLinkFollow/${messageId}/${name}`, "POST");
    }
}

export default api;