import React, { useState } from 'react';
import styled from 'styled-components';

const QuestionContainer = styled.div`
  padding: 20px;
  width: 100%;
  margin-top: 10px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
`;

const AnswerInput = styled.input`
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
`;

function TextQuestionComponent({ question, handleAnswer }) {
  const [answer, setAnswer] = useState("");

  const handleAnswerChange = (event) => {
    setAnswer(event.target.value);
    handleAnswer(question, event.target.value);
  }

  return (
    <QuestionContainer>
      <h2>{question.text}</h2>
      {question.subtext && <p>{question.subtext}</p>}
      <AnswerInput
        type="text"
        value={answer}
        onChange={handleAnswerChange}
        placeholder={question.placeholder || "Type your answer here"}
      />
    </QuestionContainer>
  );
}

export default TextQuestionComponent;