import React, { useContext, useState, useEffect } from 'react';
import { Box, Typography, Rating, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import api from '../../../services/admin-api';
import { AuthContext } from "../../../auth-context";

const ReviewsTab = ({ userId }) => {
    const [reviews, setReviews] = useState([]);
    const { token } = useContext(AuthContext);

    useEffect(() => {
        const fetchReviews = async () => {
            if (token) {
                api.getUserReviews(userId, token)
                    .then(response => {
                        console.log('Reviews:', response);
                        setReviews(response);
                    })
                    .catch(error => {
                        console.error('Error fetching reviews data:', error);
                    });
            }
        };

        fetchReviews();
    }, [userId, token]);

    function formatTimestamp(timestampArray) {
        const date = new Date(
            timestampArray[0], // year
            timestampArray[1] - 1, // month
            timestampArray[2], // day
            timestampArray[3], // hour
            timestampArray[4] // minute
        );

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}`;
    }

    return (
        <Box>
            <Typography variant="h4">User Reviews</Typography>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Review</TableCell>
                            <TableCell>Complaint</TableCell>
                            <TableCell>Link Follow</TableCell>
                            <TableCell>Phone</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reviews.map((review, index) => (
                            <TableRow key={index}>
                                <TableCell>{formatTimestamp(review.sentTime)}</TableCell>
                                <TableCell>{review.status}</TableCell>
                                <TableCell>
                                    {review.rating && <Rating value={review.rating} readOnly />}
                                    {review.questionAnswers && review.questionAnswers.map((answer, index) => (
                                        <Typography key={index}>
                                            {answer.question}: {answer.answer.join(', ')}
                                        </Typography>
                                    ))}
                                </TableCell>
                                <TableCell>{review.complaintText}</TableCell>
                                <TableCell>{review.followedLinkName}</TableCell>
                                <TableCell>{review.phone}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default ReviewsTab;