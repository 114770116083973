import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useMatch } from "react-router-dom";
import api from "../../../services/api";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import StarRating from "../../shared/questions/star-rating";
import Survey from "../../shared/questions/survey";
import { height } from "@mui/system";

export default function MainPage() {

    const temp = {
        "logoUrl": '',
        "darkTheme": false,
        "userColor": '',
        "userGradient": [
            ''
        ],
        "company": {
            "name": '',
            "description": '',
            "address": ''
        },
        "messages": {
            "initial": {
                "message": '',
                "subMessage": ''
            },
            "afterGoodReview": {
                "message": '',
                "subMessage": ''
            },
            "afterBadReview": {
                "message": '',
                "subMessage": ''
            },
            "afterImproveReview": {
                "message": '',
                "subMessage": ''
            },
            "afterComplaintLeft": {
                "message": '',
                "subMessage": ''
            },
            "questions": [],
            "rateButton": '',
            "complaintPlaceholder": '',
            "sendComplaintButton": ''
        },
        "links": [
            {
                "name": '',
                "icon": '',
                "url": ''
            }
        ],
        "autoRedirect": '',
        "phoneNumberForCallback": '',
        "currentStatus": ''
    }

    const { reqMessageId, companyName } = useParams();
    const matchMessage = useMatch("/m/:messageId");
    const matchCompany = useMatch("/:companyName");
    const navigate = useNavigate();
    const fileInputRef = useRef(null);

    const [loading, setLoading] = useState(true)
    const [template, setTemplate] = useState(temp)
    const [selectedRate, setRate] = useState('?')
    const [showStarsInCompany, setSIC] = useState(false)
    const [currentStatus, setCurrentStatus] = useState('initial')
    const [comment, setComment] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [file, setFile] = useState('')
    const [messageId, setMessageId] = useState(reqMessageId)
    const [answers, setAnswers] = useState([])
    const [allRequiredAnswered, setAllRequiredAnswered] = useState(false);
    const [seconds, setSeconds] = useState(template.autoRedirect / 1000);

    const getTemplate = async () => {
        if (matchMessage) {
            const res = await api.getReviewPageForMessageId(reqMessageId)
            if (res.status === "INTERNAL_SERVER_ERROR") navigate('error')
            res ? setTemplate(res) : navigate('error')
            if (res.currentStatus) setCurrentStatus(res.currentStatus)
            setLoading(false)
            return res
        } else if (matchCompany) {
            const res = await api.getReviewPageForCompanyName(companyName)
            if (res.status === "INTERNAL_SERVER_ERROR") navigate('error')
            res ? setTemplate(res) : navigate('error')
            if (res.currentStatus) setCurrentStatus(res.currentStatus)
            setMessageId(res.messageId)
            setLoading(false)
            return res
        }
    }

    useEffect(() => {
        getTemplate()
    }, [])

    useEffect(() => {
        let timer;
        if (currentStatus === 'afterGoodReview' && template.links && template.links.length > 0) {
            if (template.autoRedirect === 99999) {
                timer = setTimeout(() => {
                    window.location.href = template.links[0].url;
                }, template.autoRedirect);
            } else {
                setSeconds(template.autoRedirect / 1000);
                timer = setInterval(() => {
                    setSeconds(seconds => {
                        if (seconds <= 1) {
                            window.location.href = template.links[0].url;
                        }
                        return seconds - 1;
                    });
                }, 1000);
            }
        }
        return () => clearInterval(timer); // This will clear the timer when the component unmounts
    }, [currentStatus, template.links, template.autoRedirect]);

    const theme = template.darkTheme ? 'dark' : 'light'
    const userColor = template.userColor
    const userGradient = template.userGradient

    const handleStarClick = (starNumber) => {
        setRate(starNumber)
    };

    const handleRateButton = async () => {
        if (selectedRate === '?' && !allRequiredAnswered) {
            return;
        }

        let res;
        if (answers && answers.length > 0) {
            res = await api.submitReviewWithAnswers(messageId, answers);
            setSIC(4);
        } else {
            res = await api.submitReview(messageId, selectedRate + 1);
            setSIC(selectedRate);
        }
        res.text().then(function (text) {
            setCurrentStatus(text);
        });
    }

    const handleComment = (event) => {
        setComment(event.target.value);
    }

    const handleSubmit = async () => {
        const complaint = { "text": comment, "phoneNumber": phoneNumber };
        const formData = new FormData();

        formData.append('complaint', JSON.stringify(complaint));
        if (file) {
            formData.append('file', file)
        }
        const res = await api.submitComplaint(messageId, formData)
        res.text().then(function (text) {
            setCurrentStatus(text)
        })
    }

    const handleFileButton = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    }

    const handleFileChange = (event) => {
        setFile(event.target.files[0])
    }

    const handleLinkClick = async (name) => {
        await api.submitExternalLinkFollow(messageId, name)
    }

    const handleAnswer = (question, answer) => {
        setAnswers(prevAnswers => {
            const newAnswer = {
                question: question.text,
                answer: Array.isArray(answer) ? answer : [answer.toString()]
            };

            const index = prevAnswers.findIndex(a => a.question === question.text);

            let newAnswers;
            if (index !== -1) {
                // Replace existing answer
                newAnswers = [
                    ...prevAnswers.slice(0, index),
                    newAnswer,
                    ...prevAnswers.slice(index + 1)
                ];
            } else {
                // Add new answer
                newAnswers = [...prevAnswers, newAnswer];
            }

            // Check if all required questions have been answered
            const allRequiredAnswered = template.messages.questions.every(q =>
                q.required === false || newAnswers.some(a => a.question === q.text)
            );
            setAllRequiredAnswered(allRequiredAnswered);

            return newAnswers;
        });
    };

    const companyCard = (
        <div className={'company-card card'}>
            <img src={template.logoUrl} alt="Company logo" className={'company-logo'} />
            <h2 className={'company-name'}>{template.company.name}</h2>
            <p className={'company-description'}>{template.company.description}</p>
            <p className={'company-location'}>{template.company.address}</p>
            <div className={showStarsInCompany ? 'star-container-wrap expanded' : 'star-container-wrap'}>
                <StarRating selectedRate={selectedRate} userColor={userColor} userGradient={userGradient} previewMode={true} />
            </div>
        </div>
    )

    const ratingCard = (
        <div className={`rating-card card ${currentStatus === 'initial' ? 'on' : 'off'}`}>
            {
                template.messages.initial &&
                <div>
                    <h2 className={'rating-title'}>{template.messages.initial.message}<br />{template.messages.initial.subMessage}</h2>
                    <StarRating selectedRate={selectedRate} onStarClick={handleStarClick} userColor={userColor} userGradient={userGradient} />
                    <div className="rating-container" style={{ display: "inline-flex" }}>
                        <p
                            style={selectedRate === '?' && !allRequiredAnswered ? {} : { 'color': `${userColor}` }}
                            className={selectedRate === '?' ? 'selected-rating inactive' : 'selected-rating'}
                        >{selectedRate === '?' && !allRequiredAnswered ? '?' : selectedRate + 1}</p>
                        <p>| 5</p>
                    </div>
                </div>
            }
            {
                template.messages.questions &&
                <Survey questions={template.messages.questions} handleAnswer={handleAnswer} userColor={userColor} userGradient={userGradient} />
            }
            <p
                style={selectedRate === '?' && !allRequiredAnswered ? {} : {
                    'background': `linear-gradient(to left, ${userGradient[0]}, ${userGradient[1]})`,
                    'boxShadow': `0px 10px 20px 0px rgba(${parseInt(userGradient[0].slice(1, 3), 16)}, ${parseInt(userGradient[0].slice(3, 5), 16)}, ${parseInt(userGradient[0].slice(5, 7), 16)}, 0.3)`
                }}
                className={selectedRate === '?' && !allRequiredAnswered ? 'rate-btn button inactive' : 'rate-btn button'}
                onClick={handleRateButton}
            >{template.messages.rateButton}</p>
        </div>
    )

    const highRateCard = (
        <div className={`high-rate-card card ${currentStatus === 'afterGoodReview' ? 'on' : 'off'}`}>
            <h2 className={'high-rate-title'}>{template.messages.afterGoodReview.message}</h2>
            <p className={'high-rate-text'}>
                {template.messages.afterGoodReview.subMessage.replace('N', seconds)}
            </p>
            {template.autoRedirect !== 99999 && currentStatus === 'afterGoodReview' && (
                <>
                    <div className={'socials-container'}>
                        {template.links
                            ? template.links
                                .filter(item => item.name === 'Google Maps') // Filter out only the Google Maps item
                                .map((item) => {
                                    return (
                                        <a
                                            href={item.url}
                                            className={'social-button button'}
                                            key={item.name}
                                            onClick={() => { handleLinkClick(item.name) }}
                                        >
                                            <img src={item.icon} alt={item.name} />
                                            <p>{item.name}</p>
                                        </a>)
                                })
                            : ''}                       
                    </div>
                </>
            )}
            {template.autoRedirect === 99999 && (
                <div className={'socials-container'}>
                    {template.links ? template.links.map((item) => {
                        return (
                            <a
                                href={item.url}
                                className={'social-button button'}
                                key={item.name}
                                onClick={() => { handleLinkClick(item.name) }}
                            >
                                <img src={item.icon} alt={item.name} />
                                <p>{item.name}</p>
                            </a>)
                    }) : ''}
                </div>
            )}
        </div>
    )

    const lowRateCard = (
        <div className={`low-rate-card card ${currentStatus === 'afterBadReview' ? 'on' : 'off'}`}>
            <h2>{template.messages.afterBadReview.message}</h2>
            <p>{template.messages.afterBadReview.subMessage}</p>
            <textarea
                name={'commentArea'}
                id={'comment'}
                onChange={handleComment}
                className={'comment'}
                placeholder={template.messages.complaintPlaceholder}
                style={comment.length > 0 ? { 'border': `1px solid ${userColor}` } : {}}
            ></textarea>
            {matchCompany && <PhoneInput
                containerClass="phone-number-container"
                inputClass="phone-number-input"
                buttonClass="phone-number-button"
                dropdownClass="phone-number-dropdown"
                searchClass="phone-number-search"
                country={template.country}
                value={phoneNumber}
                onChange={setPhoneNumber}
                regions={'europe'}
                excludeCountries={['ru', 'by']}
            />
            }
            <div className={'comment-actions button'}>
                <div
                    className={"upload-img"}
                    style={{ 'background': `linear-gradient(to left, ${userGradient[0]}, ${userGradient[1]})`, 'boxShadow': `0px 10px 20px 0px rgba(${parseInt(userColor.slice(0, 2), 16)}, ${parseInt(userColor.slice(2, 4), 16)}, ${parseInt(userColor.slice(4, 6), 16)}, 0.3)` }}
                    onClick={handleFileButton}
                >
                    <input
                        type="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                        ref={fileInputRef}
                        name={'image'}
                        onChange={handleFileChange}
                    />
                    {<img src="/camera.svg" alt={'Upload img'} />}
                </div>
                <p
                    style={comment.length === 0 ? {} : {
                        'background': `linear-gradient(to left, ${userGradient[0]}, ${userGradient[1]})`,
                        'boxShadow': `0px 10px 20px 0px rgba(${parseInt(userColor.slice(0, 2), 16)}, ${parseInt(userColor.slice(2, 4), 16)}, ${parseInt(userColor.slice(4, 6), 16)}, 0.3)`
                    }}
                    className={comment.length === 0 ? 'rate-btn inactive' : 'rate-btn'}
                    onClick={comment.length === 0 ? console.log : handleSubmit}
                >{template.messages.sendComplaintButton}</p>
            </div>
        </div>
    )
    const improveRateCard = (
        <div className={`low-rate-card card ${currentStatus === 'afterImproveReview' ? 'on' : 'off'}`}>
            <h2>{template.messages.afterImproveReview.message}</h2>
            <p>{template.messages.afterImproveReview.subMessage}</p>
            <textarea
                name={'commentArea'}
                id={'comment'}
                onChange={handleComment}
                className={'comment'}
                placeholder={template.messages.complaintPlaceholder}
                style={comment.length > 0 ? { 'border': `1px solid ${userColor}` } : {}}
            ></textarea>
            {matchCompany && <PhoneInput
                containerClass="phone-number-container"
                inputClass="phone-number-input"
                buttonClass="phone-number-button"
                dropdownClass="phone-number-dropdown"
                searchClass="phone-number-search"
                country={template.country}
                value={phoneNumber}
                onChange={setPhoneNumber}
                regions={'europe'}
                excludeCountries={['ru', 'by']}
            />
            }
            <div className={'comment-actions button'}>
                <div
                    className={"upload-img"}
                    style={{ 'background': `linear-gradient(to left, ${userGradient[0]}, ${userGradient[1]})`, 'boxShadow': `0px 10px 20px 0px rgba(${parseInt(userColor.slice(0, 2), 16)}, ${parseInt(userColor.slice(2, 4), 16)}, ${parseInt(userColor.slice(4, 6), 16)}, 0.3)` }}
                    onClick={handleFileButton}
                >
                    <input
                        type="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                        ref={fileInputRef}
                        name={'image'}
                        onChange={handleFileChange}
                    />
                    {<img src="/camera.svg" alt={'Upload img'} />}
                </div>
                <p
                    style={comment.length === 0 ? {} : {
                        'background': `linear-gradient(to left, ${userGradient[0]}, ${userGradient[1]})`,
                        'boxShadow': `0px 10px 20px 0px rgba(${parseInt(userColor.slice(0, 2), 16)}, ${parseInt(userColor.slice(2, 4), 16)}, ${parseInt(userColor.slice(4, 6), 16)}, 0.3)`
                    }}
                    className={comment.length === 0 ? 'rate-btn inactive' : 'rate-btn'}
                    onClick={comment.length === 0 ? console.log : handleSubmit}
                >{template.messages.sendComplaintButton}</p>
            </div>
        </div>
    )

    const managerCard = (
        <div className={`manager-card card ${currentStatus === 'afterComplaintLeft' && template.phoneNumberForCallback === "" ? 'on' : 'off'}`}>
            <h2>{template.messages.afterComplaintLeft.message}</h2>
            <p>{template.messages.afterComplaintLeft.subMessage}</p>
            <img src={`/manager-${theme}.png`} alt="Our manager" />
        </div>
    )
    const callbackManagerCard = (
        <div className={`manager-card card ${currentStatus === 'afterComplaintLeft' && template.phoneNumberForCallback.length > 0 ? 'on' : 'off'}`}>
            <h2>{template.messages.afterComplaintLeft.message}</h2>
            <p>{template.messages.afterComplaintLeft.subMessage}
                <a href={`tel:${template.phoneNumberForCallback}`}>{template.phoneNumberForCallback}</a>
            </p>
            <div className="phone-container">
                <a href={`tel:${template.phoneNumberForCallback}`} rel="nofollow" class="btn-call">
                    <div class="btn-call__ico">
                        <i class="fas fa-phone-alt"></i>
                    </div>
                </a>
            </div>

        </div>
    )

    const fullPage = (
        <div className={`container ${theme}-theme`} >
            <p className={'scout-title'}>SCOUT</p>
            {companyCard}
            {ratingCard}
            {highRateCard}
            {lowRateCard}
            {improveRateCard}
            {managerCard}
            {callbackManagerCard}
        </div>
    )

    const loader = (
        <div className={`container ${theme}-theme loader`}>
            <p className={'scout-title'}>SCOUT</p>
        </div>
    )

    document.body.className = `container.${theme}-theme`;

    return (
        <>{loading ? loader : fullPage}</>
    )
}