import React, { useState } from 'react';
import styled from 'styled-components';
import StarRating from './star-rating';

const SliderContainer = styled.div`
  padding: 20px;
  width: 100%;
  margin-top: 10px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
`;

const SliderInput = styled.input`
  width: 100%;
  margin: 10px 0;
`;

function ScaleQuestionComponent({ question, handleAnswer, userColor, userGradient }) {
  const [value, setValue] = useState(-1);

  const handleValueChange = (event) => {
    setValue(event.target.value);
    handleAnswer(question, event.target.value)
  };

  return (
    <SliderContainer>
      <h2>{question.text}</h2>
      {question.subtext && <p>{question.subtext}</p>}
      {question.scale <= 5 ? (
        <StarRating
          key={question.text}
          question={question}
          selectedRate={value}
          handleAnswer={handleAnswer}
          userColor={userColor}
          userGradient={userGradient} />
      ) : (
        <SliderInput
          type="range"
          min={1}
          max={question.scale}
          step={1}
          value={value}
          onChange={handleValueChange}
        />
      )}

    </SliderContainer>
  );
}

export default ScaleQuestionComponent;