import UsersTab from './users-tab';
import { jwtDecode } from 'jwt-decode';
import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Container from '@mui/material/Container';

class AdminPage extends React.Component {

  constructor(props) {
    super(props);

    const decodedToken = jwtDecode(localStorage.getItem('id_token'));
    this.state = {
      activeTab: null,
      anchorElUser: null,
      userInfo: {
        email: decodedToken.email,
        cognitoUsername: decodedToken['cognito:username'],
        cognitoGroups: decodedToken['cognito:groups'],
      },
    };
  }

  handleTabChange = (tab) => {
    this.setState({ activeTab: tab });
  };

  handleSetting = (setting) => {
    if (setting === 'Logout') {
      this.handleLogout();
    }
  };

  handleLogout = () => {
    const { setToken, navigate } = this.props;

    localStorage.setItem('id_token', null);
    localStorage.setItem('access_token', null);
    setToken(null);

    console.log('Successful logout');

    navigate('/login');
  };

  handleOpenUserMenu = (event) => {
    if (event.currentTarget) {
      this.setState({ anchorElUser: event.currentTarget });
    } else {
      console.log('event.currentTarget is undefined');
    }
  };

  handleCloseUserMenu = () => {
    this.setState({ anchorElUser: null });
  };

  render() {

    const pages = ['Users'];
    const settings = ['Logout'];

    const { activeTab, userInfo, anchorElUser } = this.state;

    return (
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Container maxWidth="false">
            <Toolbar disableGutters>
              <Box
                component="img"
                sx={{
                  display: { xs: 'none', md: 'flex' },
                  height: 70,
                  maxHeight: { xs: 70, md: 47 },
                  margin: '5px',
                }}
                alt="logo"
                src="https://scout-review.com/images/scout-logo.png"
              />

              <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  sx={{
                    display: { xs: 'block', md: 'none' },
                  }}
                  open={false}
                >
                  {pages.map((page) => (
                    <MenuItem key={page} onClick={() => this.handleTabChange(page.toLowerCase())}>
                      <Typography textAlign="center">{page}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
              
              <Box
                component="img"
                sx={{
                  display: { xs: 'flex', md: 'none' },
                  fontFamily: 'monospace',
                  fontWeight: 700,
                  letterSpacing: '.3rem',
                  color: 'inherit',
                  textDecoration: 'none',
                  height: 70,
                  maxHeight: { xs: 70, md: 47 },
                }}
                alt="logo"
                src="https://scout-review.com/images/scout-logo.png"
              />
              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                {pages.map((page) => (
                  <Button
                    key={page}
                    onClick={() => this.handleTabChange(page.toLowerCase())}
                    sx={{ my: 2, color: 'white', display: 'block' }}
                  >
                    {page}
                  </Button>
                ))}
              </Box>

              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open settings">
                  <IconButton onClick={(event) => this.handleOpenUserMenu(event)} sx={{ p: 0 }}>
                    <Typography variant="body1" color={'white'}>
                      {userInfo.cognitoUsername} ({userInfo.cognitoGroups.join(', ')})
                    </Typography>
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={this.handleCloseUserMenu}
                >
                  {settings.map((setting, i) => (
                    <MenuItem key={i} onClick={() => this.handleSetting(setting)}>
                      <Typography textAlign="center">{setting}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>

            </Toolbar>
          </Container>
        </AppBar>
        <Box>
          {activeTab === 'users' && <UsersTab />}
          {activeTab === 'finance' && <div />}
        </Box>
      </Box>
    );
  }
}

export default AdminPage;
