import React, { useState, useEffect } from 'react';
import { TextField, Button, Avatar } from '@mui/material';

function EditLinks({userLinks, handleUpdate}) {
  const [links, setLinks] = useState(userLinks || []);

  useEffect(() => {
    setLinks(userLinks);
  }, [userLinks]);

  useEffect(() => {
    handleUpdate(links);
  }, [links, handleUpdate]);

  const addLink = () => {
    setLinks([...links, { name: '', icon: '', url: '' }]);
  };

  const removeLink = (index) => {
    setLinks(links.filter((_, i) => i !== index));
  };

  const handleChange = (index, field, value) => {
    const newLinks = [...links];
    newLinks[index][field] = value;
    setLinks(newLinks);
  };

  return (
    <div>
      {links.map((link, index) => (
        <div className='container linksContainer' key={index}>
          <Avatar alt={"N/A"} src={link.icon} size='small' variant='square'/>
          <TextField name="name" label="Name" value={link.name} onChange={(e) => handleChange(index, 'name', e.target.value)}  size='small' variant="standard" fullWidth  />
          <TextField name="icon" label="Icon" value={link.icon} onChange={(e) => handleChange(index, 'icon', e.target.value)}  size='small' variant="standard" fullWidth  />
          <TextField name="url" label="URL" value={link.url} onChange={(e) => handleChange(index, 'url', e.target.value)}  size='small' variant="standard" fullWidth  />
          <Button onClick={() => removeLink(index)}>Remove</Button>
        </div>
      ))}
      <Button onClick={addLink}>Add Link</Button>
    </div>
  );
}

export default EditLinks;